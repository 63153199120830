<div style="font-family:courier; padding-left: 5%;">
    <h2><b>INFORMACIÓN</b></h2>
    <p><b>Teléfono:</b> 914942628</p>
    <p><b>Dirección:</b>
        <br>&nbsp;&nbsp;&nbsp;C/Palomeras, 8
        <br>&nbsp;&nbsp;&nbsp;Madrid(28038), España</p>
    <p><b>Personal:</b> 
        <br>&nbsp;&nbsp;&nbsp;-Marta Montoro 
        <br>&nbsp;&nbsp;&nbsp;-Cristina Sanz
    </p>    
    <p><b>E-mail:</b> stocker@gmail.com</p>
</div>

