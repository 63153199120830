<div style="padding-left: 5%; padding-right: 20%; font-family:courier;font-family:courier;">
    <h1><b>POLÍTICA DE PRIVACIDAD</b></h1>
    <p>El presente Política de Privacidad establece los términos en que Stocker usa y protege la información que es 
        proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la 
        seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la 
        cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de 
        este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo 
        que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con 
        dichos cambios.</p>

    <h2><b>Información que es recogida</b></h2>
    <p>Nuestro sitio web podrá recoger información personal por ejemplo: Nombre,  información de contacto como  su 
        dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida 
        información específica para procesar algún pedido o realizar una entrega o facturación.</p>

    <h2><b>Uso de la información recogida</b></h2>
    <p>Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente 
        para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios.  
        Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales,
        nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún 
        beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados 
        en cualquier momento.
        Stocker está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los 
        sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>

    <h2><b>Control de su información personal</b></h2>
    <p>Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, 
        salvo que sea requerido por un juez con un orden judicial.
        Stocker Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p><br>
    <p class="text-center"><i>Estos terminos y condiciones se han generado en plantillaterminosycondicionestiendaonline.com.</i></p>
</div>