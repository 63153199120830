<div style="padding-top: 5%;padding-bottom: 5%; padding-left: 20%; padding-right: 20%;"> 
    <form class=" border border-light p-5" action="#!" [formGroup]="productForm">

        <p class="text-center h3 mb-4">Añadir Producto</p>
        <h5>Nombre:</h5>
        <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre">
        <h5>Precio:</h5>
        <input type="number" formControlName="precio" class="form-control" placeholder="Precio">
        <h5>Imagen:</h5>
        <input type="file" formControlName="imagen"  (change)="guardarImagen($event)">
        <h5>Tipo de pan:</h5>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  formControlName="pan" value="0">
            <label class="form-check-label" for="inlineRadio1">Normal</label>
            &nbsp;&nbsp;&nbsp;
            <input class="form-check-input" type="radio" formControlName="pan"  value="1">
            <label class="form-check-label" for="inlineRadio2">Integral</label>
        </div>
        <h5>¿Apto para celiacos?</h5>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio"  formControlName="gluten" value="0">
            <label class="form-check-label" for="inlineRadio3">Con gluten</label>
            &nbsp;&nbsp;&nbsp;
            <input class="form-check-input" type="radio" formControlName="gluten"  value="1">
            <label class="form-check-label" for="inlineRadio4">Sin gluten</label>
        </div>
        <h5>Ingredientes:</h5>
        <div formArrayName="ingredientes">
            <div *ngFor="let ingrediente of ingredientes.controls; index as i ">
                <h6>Ingrediente {{i+1}} :</h6>
                <div class="row" [formGroupName]="i">
                    <div class="col-sm-3">
                        <h6>Ingredinte:</h6>
                        <input type="text" formControlName="ingrediente" class="form-control" placeholder="Ingrediente">
                    </div>
                    <div class="col-sm-3">
                        <h6>Cantidad:</h6>
                        <input type="number" formControlName="cantidad" class="form-control" placeholder="Cantidad" min=0>
                    </div>
                    <div class="col-sm-3">
                        <h6>Unidad:</h6>
                        <input type="text" formControlName="unidad" class="form-control" placeholder="Unidad">
                    </div>
                    <div class="col-sm-3" style="padding-top: 37px;">
                        <button (click)="deleteIngrediente(i)"  >Eliminar</button>
                    </div>
                 </div>
            </div>
            <br>
            <button (click)="addIngrediente()">Añadir nuevo ingrediente</button>
        </div>
        <br>
        <button class="btn btn-block my-4"  type="submit" style="background-color: #3E3C3B; color: #E6E2E0;"
        (click)="onSaveForm()"> 
            Añadir Producto
        </button>
        <br>
    </form>
</div>

