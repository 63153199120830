<div style="padding-top: 5%;padding-bottom: 5%; padding-left: 20%; padding-right: 20%;"> 
    <form class=" border border-light p-5" action="#!" [formGroup]="stockForm" (submit)='onSubmit()'>

        <p class="text-center h3 mb-4">Modificar Stock</p>
        <h5>Ingrediente:</h5>
        <input type="text" formControlName="ingrediente" class="form-control" placeholder="Ingrediente">
        <div class = "alert-danger" *ngIf="ingrediente.invalid && (ingrediente.dirty || ingrediente.touched)">
            El ingrediente es obligatorio 
        </div>
        <h5>cantidad:</h5>
        <input type="number" formControlName="cantidad" class="form-control" placeholder="Cantidad">
        <div class = "alert-danger" *ngIf="cantidad.invalid && (cantidad.dirty || cantidad.touched)">
            La cantidad es obligatoria
        </div>
        <h5>Unidad:</h5>
        <input type="text" formControlName="unidad" class="form-control" placeholder="Unidad">
        <div class = "alert-danger" *ngIf="unidad.invalid && (unidad.dirty || unidad.touched)">
            La unidad es obligatoria
        </div>
        <div *ngIf="intentado" class = "alert-danger">
            Debe rellenar todos los campos para poder añadir
        </div>
        <br>
        <button class="btn btn-block my-4"  type="submit" style="background-color: #3E3C3B; color: #E6E2E0;"> 
            Modificar Stock
        </button>
        <br>
    </form>
</div>