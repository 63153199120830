<div class="container">
    <div id="myCarousel" class="carousel slide " data-ride="carousel">
    
      <ol class="carousel-indicators">
        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel" data-slide-to="1"></li>
        <li data-target="#myCarousel" data-slide-to="2"></li>
      </ol>
  
      
      <div class="carousel-inner">
        <div class="item active">
          <img src="assets/img/carrusel1.jpg" class="carousel-image" style="padding-left: 25%;">
        </div>
  
        <div class="item">
          <img src="assets/img/carrusel2.jpeg" class="carousel-image" style="padding-left: 25%;">
        </div>
  
        <div class="item">
          <img src="assets/img/carrusel3.jpeg" class="carousel-image" style="padding-left: 25%;">
        </div>
      </div>
  
      
      <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <br>
    <div class="jumbotron text-center" style="margin-bottom:0">
      <p>Somos una panaderia que realiza cada producto utilizando masa madre, harinas ecológicas, molidas a la piedra, certificadas sin aditivos y mucho amor en cada uno de ellos.</p>
    </div><br>
    <div class="text-center">
      <h1>PRODUCTOS DESTACADOS</h1>
      <br>
      <div class="row" >
        <div class="col-sm-6" *ngFor="let producto of destacados">
          <div [routerLink]="['/producto/',producto.id]"> 
            <img [src]="producto.imagen" style="height: 300px;">
            <h3>{{ producto.nombre }}</h3>
            <p>{{ producto.precio }}€ unidad</p>
          </div>
          <br>
        </div>
    </div>
  </div>
  <div>
    <img src="assets/img/panaderia1.jpeg" style="width: 100%">
  </div>
