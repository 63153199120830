import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html'
})
export class AvisosComponent implements OnInit {

  constructor() {
    window.scrollTo(500, 0);
   }

  ngOnInit(): void {
  }

}
