<div class="jumbotron text-center">
    <h2>NUESTROS HORARIOS</h2>
    <br>
    <div class="row">
        <div class="col-sm-8">
          <div id ="map" class="map" style="padding-left: 5%;">
            <app-map></app-map>
          </div>
        </div>
        <div class="col-sm-4 text-left">
          <h4>LUNES A VIERNES</h4>
          <p>De 08:30 a 20:00</p>
          <br>
          <h4>Sabados, Domingos y Festivos</h4>
          <p>De 09:30 a 14:30</p>
          <br>
          <h4>Teléfono</h4>
          <p>914942628</p>
          <br>
        </div>
    </div>
    <br>
     <p><a routerLink="contacto" >Contacto</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a routerLink="terminos" >Terminos y condiciones</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a routerLink="avisos" >Aviso Legal</a></p>
  </div>