<div style="padding-top: 5%;padding-bottom: 5%; padding-left: 20%; padding-right: 20%;" *ngIf="!pedidoEnviado"> 
    <form class=" border border-light p-5" action="#!" [formGroup]="datos" (submit)="onSubmit()" >
        <h6>Nombre:</h6>
        <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre">
        <div class = "alert-danger" *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
            <div *ngIf="nombre.errors.pattern">
                El nombre debe ser valido, no puede contener números
            </div>
            <div *ngIf="nombre.errors.required">
                El nombre es obligatorio
            </div>
        </div>
        <br>
        <h6>Apellidos:</h6>
        <input type="text" formControlName="apellidos" class="form-control" placeholder="Apelidos">
        <div class = "alert-danger" *ngIf="apellidos.invalid && (apellidos.dirty || apellidos.touched)">
            <div *ngIf="apellidos.errors.pattern">
                Los apellidos deben ser validos, no pueden contener números
            </div>
            <div *ngIf="apellidos.errors.required">
                Los apellidos son obligatorios
            </div>
        </div>
        <br>
        <h6>Fecha de recogida:</h6>
        <input type="date" formControlName="fecha" [min]='fechaFormateada'>
        <br>
        <div class = "alert-danger" *ngIf="datosEnviados">
            Debe rellenar el formulario correctamente
        </div>
        <br>
        <button class="btn btn-block" style="background-color: #3E3C3B; color: #E6E2E0;">Finalizar pedido</button>
    </form>
</div>
<div *ngIf="pedidoEnviado">
    <div class="alert alert-success">
        El pedido fue realizado con exito podra pasar a recogerlo el día {{fecha.value}}
    </div>
</div>