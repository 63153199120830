<div class="row text-center">
    <div class="col-sm-6">
        <img src="assets/img/pipas.jpeg" alt="" >
        <h5><b>Marta Montoro Rivera</b></h5>
        <p>Auxiliar de Productos Frescos</p>
        <a>@martamr</a>
    </div>
    
    <div class="col-sm-6">
        <img src="assets/img/integral.jpeg" alt="" >
        <h5 ><b>Cristina Sanz de Diego</b></h5>
        <p>Repostera</p>
        <a>@cristinasd</a>
    </div>
  </div>
  <br>
  <div class="jumbotron text-center">
      <h2 >¿Quienes somos?</h2>
      <p>Surgimos por la pasión que nos unía hacia la gastronomia, pensamos en algo que no falta en las casas a la hora de la comida, como es el pan. 
        Decidimos llevar nuestra pasión a cada uno de lo hogares, por eso abrimos las puertas de este obrador de pan artesanal hecho con masa madre, 
      los mejores ingredientes seleccionados con cariño, por su sabor y calidad.<br>
      Nuestro objetivo es hacer disfrutar del sabor autentico del pan recien hecho.</p>
  </div>
  
  <div>
    <img src="assets/img/panaderia1.jpeg" style="width: 100%">
  </div>
