import { Component } from '@angular/core';
import { InfoPaginaService } from './services/info-pagina.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public _infoPagina: InfoPaginaService) {

  }
}
